import {
  GET_DATASET_FILES_STARTED,
  GET_DATASET_FILES_SUCCESS,
  GET_DATASET_FILES_ERROR,
  POST_DATASET_FILES_STARTED,
  POST_DATASET_FILES_SUCCESS,
  POST_DATASET_FILES_ERROR,
  SET_METRICS,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetFilesReducer = (
  state = initialStates.datasetsFiles,
  { type, payload, pagination }
) => {
  switch (type) {
    case GET_DATASET_FILES_STARTED:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          error: null,
          loading: true,
        },
      };
    case GET_DATASET_FILES_SUCCESS:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          data: payload,
          pagination,
          error: null,
          loading: false,
        },
      };
    case GET_DATASET_FILES_ERROR:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          error: payload,
          loading: false,
        },
      };
    case SET_METRICS:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          metrics: payload,
        },
      };
    case POST_DATASET_FILES_STARTED:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          error: null,
          loading: true,
        },
      };
    case POST_DATASET_FILES_SUCCESS:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          data: payload.record,
          pagination,
          error: null,
          loading: false,
        },
      };
    case POST_DATASET_FILES_ERROR:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          error: payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default DatasetFilesReducer;
