import {
  GET_DATASET_STARTED,
  GET_DATASET_SUCCESS,
  GET_DATASET_ERROR,
  POST_DATASET_STARTED,
  POST_DATASET_SUCCESS,
  POST_DATASET_ERROR,
  PUT_DATASET_STARTED,
  PUT_DATASET_SUCCESS,
  PUT_DATASET_ERROR,
  DELETE_DATASET_STARTED,
  DELETE_DATASET_SUCCESS,
  DELETE_DATASET_ERROR,
} from "../../../../constants";
import initialStates from "../../../../initail-states";

const DatasetReducer = (state = initialStates.datasets, { type, payload }) => {
  switch (type) {
    case GET_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case GET_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case POST_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case POST_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case PUT_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PUT_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case PUT_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case DELETE_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default DatasetReducer;