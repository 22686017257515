import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Reducers from "../content/reducers";
import initialStates from "../initail-states";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ basename: "/" });
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["settings", "auth", "signalR", ],
};

const persistedReducer = persistReducer(persistConfig, Reducers);
const store = createStore(
  persistedReducer,
  initialStates,
  composeWithDevTools(
    applyMiddleware(routerMiddleware(History), thunk)
    )
);
let persistor = persistStore(store);
export { store, history, persistor };
