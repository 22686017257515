import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
// import { logo } from "../../../config";
import logo from "../../../assets/images/nubyla-svg-purple.svg";
import "./404.scss";

const PageNotFound = ({ errorBoundary }) => (
  <div className="pagenotfound-container">
    <Row className="justify-content-center">
      <Link to="/" style={{ marginTop: 30 }}>
        <img src={logo} alt="" className="logo-login logo-dark" width={180} />
      </Link>
    </Row>
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1 className={errorBoundary ? "small-font" : ""}>
            {errorBoundary ? "OOPS!" : "404"}
          </h1>
        </div>
        <h2>
          {errorBoundary
            ? "Something went wrong!"
            : "We are sorry, Page not found!"}
        </h2>
        <p>
          {errorBoundary
            ? "The page you are looking for might have been broken OR happening because of Internet connectivity"
            : "The page you are looking for might have been removed had its name changed or is temporarily unavailable."}
        </p>
        <Link
          onClick={() => {
            errorBoundary && window.location.reload(false);
            return;
          }}
          to={errorBoundary ? "#" : "/"}
        >
          {errorBoundary ? "Refresh" : "Back To Homepage"}
        </Link>
      </div>
    </div>
  </div>
);
export default PageNotFound;
