import {
  GET_WORKSPACE_BYID_STARTED,
  GET_WORKSPACE_BYID_SUCCESS,
  GET_WORKSPACE_BYID_ERROR,
  POST_WORKSPACE_STARTED,
  POST_WORKSPACE_SUCCESS,
  POST_WORKSPACE_ERROR,
  PUT_WORKSPACE_STARTED,
  PUT_WORKSPACE_SUCCESS,
  PUT_WORKSPACE_ERROR,
  DELETE_WORKSPACE_STARTED,
  DELETE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_ERROR,
  GET_WORKSPACES_STARTED,
  GET_WORKSPACES_SUCCESS,
  GET_WORKSPACES_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const WorkspaceReducer = (
  state = initialStates.workspaces,
  { type, payload, pagination }
) => {
  switch (type) {
    case GET_WORKSPACE_BYID_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_WORKSPACE_BYID_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_WORKSPACE_BYID_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_WORKSPACES_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_WORKSPACES_SUCCESS:
      return {
        ...state,
        data: payload,
        pagination,
        error: null,
        loading: false,
      };
    case GET_WORKSPACES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case POST_WORKSPACE_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_WORKSPACE_SUCCESS:
      return {
        ...state,
        data: [...state.data, payload],
        pagination,
        error: null,
        loading: false,
      };
    case POST_WORKSPACE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case PUT_WORKSPACE_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PUT_WORKSPACE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case PUT_WORKSPACE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_WORKSPACE_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_WORKSPACE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case DELETE_WORKSPACE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default WorkspaceReducer;
