import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/style.scss";
import Spinner from "./common/components/Spinner/Spinner";
import { history, persistor, store } from "./operations/center/store";
import ErrorBoundary from "./common/components/ErrorBoundary/ErrorBoundary";
import "leaflet/dist/leaflet.css";
import "leaflet.polylinemeasure/Leaflet.PolylineMeasure.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientID } from "./config";

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./app")), 1000);
    })
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <ToastContainer position="bottom-right" />
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <ErrorBoundary>
          <GoogleOAuthProvider clientId={googleClientID}>
            <App />
            </GoogleOAuthProvider>
          </ErrorBoundary>
        </Router>
      </PersistGate>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
// if('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//       navigator.serviceWorker.register('/serviceworker.js')
//           .then((reg) => console.log('Success: ', reg.scope))
//           .catch((err) => console.log('Failure: ', err));
//   })
// }