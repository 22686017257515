import { SET_SELECTED_ROWS } from "../../../constants";
import initialStates from "../../../initail-states";


const DataTableReducer = (state = initialStates.dataTable, { type, payload }) => {
    switch (type) {
      case SET_SELECTED_ROWS:
        return {
          ...state,
          [payload.tableId]:payload.data
        };

      default:
        return state;
    }
  }
  export default DataTableReducer