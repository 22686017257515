import React from "react";
import "./Spinner.scss";
const Spinner = (props) => {
  return props?.overlay ? (
    props?.enable ? (
      <>
        {props?.center ? (
          <center>
            <div
              className="fallback-spinner"
              style={{
                zIndex: 2000,
                backgroundColor: "white",
                opacity: 0.5,
              }}
            >
              <div className="loading component-loader s">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
              </div>
            </div>
          </center>
        ) : (
          <>
            <div
              className="fallback-spinner"
              style={{
                zIndex: 2000,
                backgroundColor: "white",
                opacity: 0.5,
              }}
            >
              <div className="loading component-loader s">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
              </div>
            </div>
          </>
        )}
      </>
    ) : (
      <></>
    )
  ) : (
    <>
      {props?.center ? (
        <center>
          <div
            className="fallback-spinner"
            style={{
              maxHeight: props.maxWidthHeight ?? "80px",
              maxWidth: props.maxWidthHeight ?? "inherit",
            }}
          >
            <div
              className="loading component-loader s"
              style={{
                maxWidth: props.maxWidthHeight ?? "inherit",
                maxHeight: props.maxWidthHeight ?? "inherit",
              }}
            >
              <div className="effect-1 effects"></div>
              <div className="effect-2 effects"></div>
              <div className="effect-3 effects"></div>
            </div>
          </div>
        </center>
      ) : (
        <div
          className="fallback-spinner"
          style={{
            maxHeight: props.maxWidthHeight ?? "80px",
            maxWidth: props.maxWidthHeight ?? "inherit",
          }}
        >
          <div
            className="loading component-loader s"
            style={{
              maxWidth: props.maxWidthHeight ?? "inherit",
              maxHeight: props.maxWidthHeight ?? "inherit",
              left: props.maxWidthHeight
                ? 50 - props.maxWidthHeight / 5 + "%"
                : "47%",
            }}
          >
            <div className="effect-1 effects"></div>
            <div className="effect-2 effects"></div>
            <div className="effect-3 effects"></div>
          </div>
        </div>
      )}
    </>
  );
};
export default Spinner;
