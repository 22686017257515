import {
    GET_CHART_DATA_STARTED,
    GET_CHART_DATA_SUCCESS,
    GET_CHART_DATA_ERROR,
  } from "../../../constants";
  import initialStates from "../../../initail-states";
  
  const SalmaReducer = (state = initialStates.salma,{ type, payload }) => {
    switch (type) {
      case GET_CHART_DATA_STARTED:
        return {
          ...state,
          error: null,
        };
      case GET_CHART_DATA_SUCCESS:
        return {
          ...state,
          data: payload.data,
          error: null,
        };
      case GET_CHART_DATA_ERROR:
        return {
          ...state,
          error: payload,
        };
      default:
        return state;
    }
  }
  
  export default SalmaReducer;