import { SIGNAL_R_ERROR, SIGNAL_R_RESET, SIGNAL_R_STARTED, SIGNAL_R_SUCCESS } from "../../../constants";
import initialStates from "../../../initail-states";

const SignalRReducer = (state = initialStates.signalR, { type, payload }) => {
  switch (type) {
    case SIGNAL_R_STARTED:
      return {
        ...state,
        error: null,
      };
    case SIGNAL_R_SUCCESS:
      return {
        ...state,
        data: payload,
        counter: payload ? state.counter + 1 : state.counter,
        error: null,
      };
    case SIGNAL_R_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SIGNAL_R_RESET:
      return {
        ...state,
        counter: 0,
        error: null,
      };
    default:
      return state;
  }
}

export default SignalRReducer;