import { DATASET_NOTIFICATION } from "../../../constants";
import initialStates from "../../../initail-states";

const broadcastReducer = (
  state = initialStates.broadcasts,
  { type, payload }
) => {
  switch (type) {
    case DATASET_NOTIFICATION:
      return {
        ...state,
        dataset: payload,
      };
    default:
      return state;
  }
};

export default broadcastReducer;
