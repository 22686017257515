import { combineReducers } from "redux";
import broadcasts from "../content/broadcasts/reducer";
import auth from "../content/auth/reducer";
import globalTimer from "../content/globalTimer/reducer";
import settings from "../content/settings/reducer";
import errors from "../content/errors/reducer";
import datasets from "../content/datasets/reducer";
import datasetsInfo from "../content/datasetsInfo/reducer";
import datasetsFiles from "../content/datasetsFiles/reducer";
import datasetVersions from "../content/datasetVersions/reducer";
import datasetVersionData from "../content/datasetVersionData/reducer";
import datasetVersionDetailData from "../content/datasetVersionDetailData/reducer";
import datasetVersionDataCleaning from "../content/datasetVersionDataCleaning/reducer";
import yahyaDatasets from "../content/yahya/yahya-datasets/reducer";
import salma from "../content/salma/reducer";
import person from "../content/yazeed/reducer";
import mlEngineTest from "../content/yazeed/machine-learning-engine/reducer";
import workspaces from "../content/workspaces/reducer";
import workspaceStats from "../content/workspaceStats/reducer";
import sharedWorkspaces from "../content/sharedWorkspaces/reducer";
import workspaceModels from "../content/workspaceModel/reducer";
import analyticsCards from "../content/analyticsCards/reducer";
import mlEngine from "../content/mlEngine/reducer";
import signalR from "./signal-r/reducer";
import userNotifications from "./userNotifications/reducer";
import dataTable from "./dataTable/reducer/index";
import deleteModal from "./deleteModal/reducer/index";
import successModal from "./successModal/reducer/index";
import analytics from "./analytics/reducer/index";
import fileUpload from "./fileUploader/fileUploaderReducer";
import globalModals from "./globalModal/reducer/index";
import userHelper from "./userGuide/reducer/index";
import dataMapping from "./dataMapping/dataMappingReducer";
import dataManipulation from "./dataManipulation/reducer";

import { REMOVE_AUTH } from "../constants";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const appReducer = combineReducers({
  broadcasts,
  auth,
  analytics,
  settings,
  errors,
  globalTimer,
  datasets,
  datasetsInfo,
  datasetsFiles,
  datasetVersions,
  datasetVersionData,
  datasetVersionDetailData,
  datasetVersionDataCleaning,
  yahyaDatasets,
  salma,
  person,
  mlEngineTest,
  workspaces,
  workspaceStats,
  sharedWorkspaces,
  workspaceModels,
  analyticsCards,
  mlEngine,
  signalR,
  userNotifications,
  dataTable,
  deleteModal,
  successModal,
  globalModals,
  fileUpload,
  userHelper,
  dataMapping,
  dataManipulation,
});
const Reducers = (state, action) => {
  if (action.type === REMOVE_AUTH) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeInaccuratestem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default Reducers;
