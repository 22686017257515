import {
  AUTH_STARTED,
  AUTH_SUCCESS,
  AUTH_ERROR,
  SET_AUTH,
  REMOVE_AUTH,
  SET_TOKEN,
  REMOVE_TOKEN,

} from "../../../constants";
import initialStates from "../../../initail-states";

const AuthenticationReducer = (
  state = initialStates.auth,
  { type, payload }
) => {
  switch (type) {
    case AUTH_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        record: { ...state.record,...payload },
        error: null,
        loading: false,
      };
    case SET_AUTH:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case REMOVE_AUTH:
      return {
        ...state,
        record: null,
        error: null,
        loading: false,
      };
    case SET_TOKEN:
      return {
        ...state,
        record: {
          ...state.record,
          ...payload
        },
        laoding: false,
        error: null,
      };
    case REMOVE_TOKEN:

      return  {
        ...state,
        record: {
          ...state.record,
          ...payload
        },
        laoding: false,
        error: null,
      };
    default:
      return state;
  }
};

export default AuthenticationReducer;
