import {
  GET_SHARED_WORKSPACES_STARTED,
  GET_SHARED_WORKSPACES_SUCCESS,
  GET_SHARED_WORKSPACES_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const SharedWorkspaceReducer = (
  state = initialStates.sharedWorkspaces,
  { type, payload }
) => {
  switch (type) {
    case GET_SHARED_WORKSPACES_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_SHARED_WORKSPACES_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_SHARED_WORKSPACES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default SharedWorkspaceReducer;
