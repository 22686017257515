import {
  GET_DATASET_VERSIONS_STARTED,
  GET_DATASET_VERSIONS_SUCCESS,
  GET_DATASET_VERSIONS_ERROR,
  POST_DATASET_VERSIONS_STARTED,
  POST_DATASET_VERSIONS_SUCCESS,
  POST_DATASET_VERSIONS_ERROR
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetVersionsReducer = (state = initialStates.datasetVersions, { type, payload }) => {
  switch (type) {
    case GET_DATASET_VERSIONS_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_VERSIONS_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_DATASET_VERSIONS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case POST_DATASET_VERSIONS_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_DATASET_VERSIONS_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case POST_DATASET_VERSIONS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default DatasetVersionsReducer;