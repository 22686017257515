import {
  USER_HELPER_COMPLETED,
  USER_HELPER_NEXT,
  USER_HELPER_OFF,
  USER_HELPER_ON,
  USER_HELPER_RESET,
  USER_HELPER_UPDATE,
} from "../../../constants";
import initialStates from "../../../initail-states";

const UserHelperReducer = (
  state = initialStates.userHelper,
  { type, payload }
) => {
  switch (type) {
    case USER_HELPER_ON:
      return {
        ...state,
        active: true,
        run:true,
        currentStep:0
      };
    case USER_HELPER_OFF:
      return {
        ...state,
        active: false,
        run:false
      };
    case USER_HELPER_COMPLETED:
      return {
        ...state,
        isCompleted: true,
        active: false,
        run: false,
      };
    case USER_HELPER_RESET:
      return {
        ...state,
        currentStep: 0,
        isCompleted: false,
        active: true,
        run:true
      };
    case USER_HELPER_UPDATE:
      return {
        ...state,
        ...payload
      }
      case USER_HELPER_NEXT:
        return {
          ...state,
          currentStep: state.currentStep + 1,
        }
    default:
      return state;
  }
};

export default UserHelperReducer;
