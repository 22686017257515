import {
  ADD_FILES,
  CLEAR_VALUES,
  FILE_COMPLETED,
  FILE_FAILED,
  REMOVE_FILE,
  UPDATE_FILE,
} from "../../constants";
import initialStates from "../../initail-states";

const fileUploaderReducer = (
  state = { ...initialStates.fileUpload },
  action
) => {
  // console.log({action});
  switch (action.type) {
    case ADD_FILES:
      return {
        ...state,
        files: [ ...action.payload,...state.files],
        pending: state.pending + action.payload.length,
      };
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((x) => x.id !== action.payload),
        pending: state.pending - 1 < 0 ? 0 : state.pending - 1,
      };
    case UPDATE_FILE:
      return {
        ...state,
        files: state.files.map((x) => {

          if (x.id !== action.payload.id) {
            return x;
          }
          return {
            ...x,
            ...action.payload,
          };
        }),
      };
    case CLEAR_VALUES:
      return { ...initialStates.fileUpload };
    case FILE_COMPLETED:
      return {
        ...state,
        completed: state.completed + 1,
        pending: state.pending - 1 < 0 ? 0 : state.pending - 1,
      };
    case FILE_FAILED:
      return {
        ...state,
        failed: state.failed + 1,
        pending: state.pending - 1 < 0 ? 0 : state.pending - 1,
      };

    default:
      return state;
  }
};
export default fileUploaderReducer;
