import { CLOSE_SUCCESS_MODAL, OPEN_SUCCESS_MODAL } from "../../../constants";
import initialStates from "../../../initail-states";

const DeleteModalReducer = (state = initialStates.successModal, { type }) => {
  switch (type) {
    case OPEN_SUCCESS_MODAL:
      return {
        open: true,
      };
    case CLOSE_SUCCESS_MODAL:
      return {
        open: false,
      };
    default:
      return state;
  }
};
export default DeleteModalReducer;
