import {
  ADD_DATA_MANIPULATION,
  RESET_DATA_MANIPULATION,
} from "../../constants";
import initialStates from "../../initail-states";

const dataManipulationReducer = (
  state = initialStates.dataManipulation,
  { type, payload }
) => {
  switch (type) {
    case ADD_DATA_MANIPULATION:
      return {
        ...state,
        ...payload,
      };
    case RESET_DATA_MANIPULATION:
      return {

        ...initialStates.dataManipulation,
      };
    default:
      return state;
  }
};
export default dataManipulationReducer;