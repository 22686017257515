import {
    GET_PERSON_BYID_STARTED,
    GET_PERSON_BYID_SUCCESS,
    GET_PERSON_BYID_ERROR,
    POST_PERSON_STARTED,
    POST_PERSON_SUCCESS,
    POST_PERSON_ERROR,
    PUT_PERSON_STARTED,
    PUT_PERSON_SUCCESS,
    PUT_PERSON_ERROR,
    DELETE_PERSON_STARTED,
    DELETE_PERSON_SUCCESS,
    DELETE_PERSON_ERROR,
    GET_PEOPLE_STARTED,
    GET_PEOPLE_SUCCESS,
    GET_PEOPLE_ERROR,
  } from "../../../constants";
  import initialStates from "../../../initail-states";
  
  const PersonReducer = (state = initialStates.person, { type, payload }) => {
    switch (type) {
      case GET_PERSON_BYID_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case GET_PERSON_BYID_SUCCESS:
        return {
          ...state,
          data: payload,
          error: null,
          loading: false,
        };
      case GET_PERSON_BYID_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case GET_PEOPLE_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case GET_PEOPLE_SUCCESS:
        return {
          ...state,
          data: payload,
          error: null,
          loading: false,
        };
      case GET_PEOPLE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case POST_PERSON_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case POST_PERSON_SUCCESS:
        return {
          ...state,
          data: [...state.data, payload],
          error: null,
          loading: false,
        };
      case POST_PERSON_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case PUT_PERSON_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case PUT_PERSON_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
          };
      case PUT_PERSON_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case DELETE_PERSON_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case DELETE_PERSON_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
        };
      case DELETE_PERSON_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      default:
        return state;
    }
  }
  
  export default PersonReducer;