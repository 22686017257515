import {
  ADD_ANALYSIS,
  ADD_BULK_ANALYSIS,
  ADD_PINNED_CHART,
  APPEND_CHART_TO_CONTAINER,
  CLEAR_ANALYTICS,
  CLEAR_PINNED_CHARTS,
  GET_ANALYTICS_ERROR,
  GET_ANALYTICS_STARTED,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTIC_SUCCESS,
  MAIN_BOARD_ERROR,
  REMOVE_CHILD_ANALYSIS_FROM_CONTAINER,
  REMOVE_PINNED_CHART,
  RENAME_TAB,
  SET_ACTIVE_HTAB,
  SET_ANALYSIS_TYPES,
  SET_ANALYSIS_TYPES_ERROR,
  SET_DATA_ANALYTICS,
  SET_DIRTY_FALSE,
  SET_HTAB,
  SET_SAVED_HTABS,
  SET_SAVED_HTABS_ERROR,
  START_SAVING_ANALYSIS,
  STOP_SAVING_ANALYSIS,
  UPDATE_ANALYSIS,
  UPDATE_MODIFIED_QUEUE,
  UPDATE_PINNED_CHART,
  UPDATE_TAB_AND_ANALYSIS,
} from "../../../constants";
import initialStates from "../../../initail-states";

const AnalyticsReducer = (
  state = initialStates.analytics,
  { type, payload }
) => {
  switch (type) {
    case GET_ANALYTICS_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_ANALYTIC_SUCCESS:
      return {
        ...state,
        analyticDetails: payload,
        error: null,
        loading: false,
      };
    case SET_HTAB:
      return {
        ...state,
        HTabs: payload,
      };
    case GET_ANALYTICS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADD_PINNED_CHART:
      return {
        ...state,
        pinnedCharts: [...state.pinnedCharts, payload],
      };
    case UPDATE_PINNED_CHART:
      let newData = state.pinnedCharts.map((i) => {
        if (i.id === payload.id) i = { ...i, ...payload };
        return i;
      });
      return {
        ...state,
        pinnedCharts: newData,
      };

    case UPDATE_ANALYSIS:
      if (state.activeHTab.id !== 0) {
        if (payload.containerId) {
          // const updatedAnalysis = {
          //   ...(state.analysis[state.activeHTab.id]
          //     .find((x) => x.containerId === payload.containerId)
          //     .childAnalysis.find((x) => x.id === payload.id) || {}),
          //   ...payload.data,
          // };
          if (payload.childId) {
            return {
              ...state,
              analysis: {
                ...state.analysis,
                [state.activeHTab.id]: state.analysis[state.activeHTab.id].map(
                  (x) =>
                    x.containerId === payload.containerId
                      ? {
                          ...x,
                          childAnalysis: x.childAnalysis.map((i) =>
                            i.id !== payload.id
                              ? i
                              : {
                                  ...i,
                                  childArray: i.childArray.map((ch) =>
                                    ch.id === payload.childId
                                      ? { ...ch, ...payload.data }
                                      : ch
                                  ),
                                }
                          ),
                        }
                      : x
                ),
              },
            };
          } else
            return {
              ...state,
              analysis: {
                ...state.analysis,
                [state.activeHTab.id]: state.analysis[state.activeHTab.id].map(
                  (x) =>
                    x.containerId === payload.containerId
                      ? {
                          ...x,
                          childAnalysis: x.childAnalysis.map((i) =>
                            i.id !== payload.id ? i : { ...i, ...payload.data }
                          ),
                        }
                      : x
                ),
              },
            };
        } else {
          const updatedAnalysis = {
            ...(state.analysis[state.activeHTab.id]?.find(
              (x) => x.id === payload.id
            ) || {}),
            ...payload.data,
          };
          return {
            ...state,
            analysis: {
              ...state.analysis,
              [state.activeHTab.id]: state.analysis[state.activeHTab.id].map(
                (x) => {
                  if (x.id === payload.id) x = updatedAnalysis;
                  return x;
                }
              ),
            },
          };
        }
      } else {
        return { ...state };
      }

    case REMOVE_PINNED_CHART:
      if (payload.containerId) {
        let containerElement = state.pinnedCharts.find(
          (x) => x.containerId === payload.containerId
        );
        let chart = containerElement.childAnalysis?.find(
          (x) => x.analysisID === payload.id
        );

        if (state.analysis[chart.parentAnalysisID]) {
          return {
            ...state,
            pinnedCharts: state.pinnedCharts.map((x) =>
              x.containerId === payload.containerId
                ? {
                    ...x,
                    childAnalysis: x.childAnalysis.filter(
                      (i) => i.id !== payload.id
                    ),
                  }
                : x
            ),
            analysis: {
              ...state.analysis,
              [chart.parentAnalysisID]: state.analysis[
                chart.parentAnalysisID
              ].map((x) =>
                x.containerId === payload.containerId
                  ? {
                      ...x,
                      childAnalysis: x.childAnalysis.map((i) =>
                        i.id !== payload.id ? i : { ...i, isPinned: false }
                      ),
                    }
                  : x
              ),
            },
          };
        }
        return {
          ...state,
          pinnedCharts: state.pinnedCharts.map((x) =>
            x.containerId === payload.containerId
              ? {
                  ...x,
                  childAnalysis: x.childAnalysis.filter(
                    (i) => i.id !== payload.id
                  ),
                }
              : x
          ),
        };
      } else {
        let chart = state.pinnedCharts.find((x) => x.id === payload.id);
        if (state.analysis[chart.parentAnalysisID]) {
          return {
            ...state,
            pinnedCharts: state.pinnedCharts.filter((x) => x.id !== payload.id),
            analysis: {
              ...state.analysis,
              [chart.parentAnalysisID]: state.analysis[
                chart.parentAnalysisID
              ].map((x) =>
                x.id === payload.id ? { ...x, isPinned: false } : x
              ),
            },
          };
        } else {
          return {
            ...state,
            pinnedCharts: state.pinnedCharts.filter((x) => x.id !== payload.id),
          };
        }
      }

    //   //find the chart from pinned item
    //   let newUpdatedState = {};
    //   let chart = undefined;
    //   let containerElement = state.pinnedCharts.find(
    //     (x) => (x.containerId || x.id) === payload.containerId
    //   );
    //   if (containerElement) {
    //     chart = containerElement.childAnalysis?.find(
    //       (x) => x.analysisID === payload.id
    //     );
    //   } else {
    //     chart = state.pinnedCharts.find((x) => x.analysisID === payload.id);
    //   }
    //   let updatedAnalysisObj = [];
    //   //condition to check if analysis has object of this parent so we can unpin it from that tab as well
    //   if (state.analysis[chart.parentAnalysisID]) {
    //     //condition if it is a container
    //     if (containerElement) {
    //       //updated value of analysis under specific container
    //       // updatedAnalysisObj = JSON.parse(
    //       //   JSON.stringify(state.analysis[chart.parentAnalysisID].map((x) => {
    //       //       let container = { ...x };
    //       //       if (container.containerId === payload.containerId) {
    //       //         let childAnalysis = [...container.childAnalysis];
    //       //         childAnalysis = childAnalysis.map((i) => {
    //       //           if (i.analysisID === payload.id)
    //       //             i = { ...i, isPinned: false };
    //       //           return i;
    //       //         });
    //       //         container = { ...container, childAnalysis };
    //       //       }
    //       //       return container;
    //       //     })
    //       //   )
    //       // );
    //       const newPinnedChart = [
    //         ...state.pinnedCharts.map((pinChart) => {
    //           let pinChartNew = { ...pinChart };
    //           if (pinChartNew.containerId === payload.containerId) {
    //             let childAnalysis = [...pinChartNew.childAnalysis];
    //             childAnalysis = pinChartNew.childAnalysis.filter(
    //               (x) => x.analysisID !== payload.id
    //             );
    //             pinChartNew = { ...pinChartNew, childAnalysis };
    //           }
    //           return pinChartNew;
    //         }),
    //       ];
    //       return {
    //         //remove chaild analysis from container
    //         ...state,
    //         pinnedCharts: newPinnedChart,
    //         analysis: {
    //           ...state.analysis,
    //           // [chart.parentAnalysisID]: updatedAnalysisObj,
    //           [chart.parentAnalysisID]: state.analysis[
    //             chart.parentAnalysisID
    //           ].map((x) => {
    //             let container = { ...x };
    //             if (container.containerId === payload.containerId) {
    //               let childAnalysis = container.childAnalysis;
    //               childAnalysis = childAnalysis.map((i) => {
    //                 let ii = i;
    //                 if (ii.analysisID === payload.id)
    //                   ii = { ...ii, isPinned: false };
    //                 return ii;
    //               });
    //               container = { ...container, childAnalysis };
    //             }
    //             return container;
    //           }),
    //         },
    //       };
    //     } else {
    //       // update the analysis directly
    //       updatedAnalysisObj = state.analysis[chart.parentAnalysisID]?.map(
    //         (x) => {
    //           if (x.id === payload.id) {
    //             x = { ...x, isPinned: false };
    //           }
    //           return x;
    //         }
    //       );
    //       return {
    //         ...state,
    //         pinnedCharts: state.pinnedCharts.filter(
    //           (chart) => chart.id !== payload.id
    //         ),
    //         analysis: {
    //           ...state.analysis,
    //           [chart.parentAnalysisID]: [...updatedAnalysisObj],
    //         },
    //       };
    //     }
    //   }
    //   // only remove the chart because it's tab is not opened
    //   else {
    //     if (containerElement) {
    //       return {
    //         ...state,
    //         pinnedCharts: [
    //           ...state.pinnedCharts.map((pinChart) => {
    //             if (pinChart.containerId === payload.containerId) {
    //               pinChart.childAnalysis = pinChart.childAnalysis.filter(
    //                 (x) => x.analysisID !== payload.id
    //               );
    //             }
    //             return pinChart;
    //           }),
    //         ],
    //       };
    //     }
    //     return {
    //       ...state,
    //       pinnedCharts: state.pinnedCharts.filter(
    //         (chart) => chart.id !== payload.id
    //       ),
    //     };
    //   }

    // // return { ...newUpdatedState };
    case RENAME_TAB:
      if (state.HTabs.find((tab) => tab.id === payload.id)) {
        if (state.activeHTab.id === payload.id) {
          return {
            ...state,
            HTabs: state.HTabs.map((tab) =>
              tab.id === payload.id
                ? { ...tab, name: payload.name, analysisName: payload.name }
                : tab
            ),
            activeHTab: {
              ...state.activeHTab,
              name: payload.name,
              analysisName: payload.name,
            },
            savedHTabs: state.savedHTabs.map((tab) =>
              tab.id === payload.id
                ? { ...tab, name: payload.name, analysisName: payload.name }
                : tab
            ),
          };
        } else {
          return {
            ...state,
            HTabs: state.HTabs.map((tab) =>
              tab.id === payload.id
                ? { ...tab, name: payload.name, analysisName: payload.name }
                : tab
            ),

            savedHTabs: state.savedHTabs.map((tab) =>
              tab.id === payload.id
                ? { ...tab, name: payload.name, analysisName: payload.name }
                : tab
            ),
          };
        }
      } else {
        return {
          ...state,

          savedHTabs: state.savedHTabs.map((tab) =>
            tab.id === payload.id
              ? { ...tab, name: payload.name, analysisName: payload.name }
              : tab
          ),
        };
      }

    case CLEAR_PINNED_CHARTS:
      return {
        ...state,
        pinnedCharts: [],
      };
    case SET_DATA_ANALYTICS:
      return {
        ...state,
        dataAnalytics: payload,
      };
    case SET_ACTIVE_HTAB:
      return {
        ...state,
        activeHTab: payload,
      };
    case ADD_ANALYSIS:
      return {
        ...state,
        activeHTab: { ...state.activeHTab },
        HTabs: state.HTabs.map((tab) =>
          tab.id === state.activeHTab.id ? { ...tab } : tab
        ),
        analysis: payload,
      };

    case ADD_BULK_ANALYSIS:
      if (payload.isMainBoard) {
        return {
          ...state,
          pinnedCharts: [...payload.charts],
        };
      }
      return {
        ...state,
        analysis: {
          ...(state.analysis || {}),
          [payload.parentAnalysisID]: [
            ...(state.analysis?.[payload.parentAnalysisID]?.filter(
              (x) => !x.childAnalysis?.length
            ) || []),

            ...payload.charts,
          ],
        },
      };

    case CLEAR_ANALYTICS:
      return {
        ...initialStates.analytics,
      };
    case MAIN_BOARD_ERROR:
      return {
        ...state,
        mainBoardError: payload,
      };
    case SET_ANALYSIS_TYPES:
      return {
        ...state,
        analysisTypes: payload,
      };
    case SET_ANALYSIS_TYPES_ERROR:
      return {
        ...state,
        analysisTypesError: payload,
      };
    case SET_SAVED_HTABS:
      return {
        ...state,
        savedHTabs: payload,
      };
    case SET_SAVED_HTABS_ERROR:
      return {
        ...state,
        savedHTabsError: payload,
      };
    case APPEND_CHART_TO_CONTAINER:
      const container = state.analysis?.[state.activeHTab.id]?.find(
        (x) => x.id === payload.containerId
      );
      const newChartToContainer = [
        ...(container?.childAnalysis || []),
        payload.chart,
      ];
      if (container)
        return {
          ...state,
          analysis: {
            ...state.analysis,
            [payload.activeHTab.id]: state.analysis[payload.activeHTab.id]?.map(
              (x) => {
                if (x.id === payload.containerId) {
                  return {
                    ...x,
                    childAnalysis: newChartToContainer,
                  };
                }
                return x;
              }
            ),
          },
          activeHTab: { ...state.activeHTab },
          HTabs: state.HTabs.map((tab) =>
            tab.id === state.activeHTab.id ? { ...tab } : tab
          ),
        };
      else {
        return {
          ...state,
          analysis: {
            ...state.analysis,
            [payload.activeHTab.id]: [
              ...state.analysis[payload.activeHTab.id],
              {
                id: payload.containerId,
                multiple: true,
              },
            ],
          },
          activeHTab: { ...state.activeHTab },
          HTabs: state.HTabs.map((tab) =>
            tab.id === state.activeHTab.id ? { ...tab } : tab
          ),
        };
      }
    case REMOVE_CHILD_ANALYSIS_FROM_CONTAINER:
      const newChildAnalysis1 = state.analysis[state.activeHTab.id].find(
        (x) => (x.containerId || x.id) === payload.containerId
      );
      const newChildAnalysis = newChildAnalysis1.childAnalysis.filter(
        (x) => (x.analysisID || x.id) !== payload.id
      );

      return {
        ...state,
        analysis: {
          ...state.analysis,
          [state.activeHTab.id]: state.analysis[state.activeHTab.id].map(
            (x) => {
              if (x.id === payload.containerId) {
                return {
                  ...x,
                  childAnalysis: newChildAnalysis,
                };
              }
              return x;
            }
          ),
        },
        activeHTab: { ...state.activeHTab },
        HTabs: state.HTabs.map((tab) =>
          tab.id === state.activeHTab.id ? { ...tab } : tab
        ),
      };
    case SET_DIRTY_FALSE:
      return {
        ...state,
        activeHTab: { ...state.activeHTab, dirty: false },
        HTabs: state.HTabs.map((tab) =>
          tab.id === state.activeHTab.id ? { ...tab, dirty: false } : tab
        ),
      };
    case UPDATE_TAB_AND_ANALYSIS:
      const { activeHTab, analysis, HTabs } = { ...state };
      //newTab contains the new id for the tab to be replaced with
      const newHTab = HTabs.map((tab) => {
        if (tab.id === activeHTab.id) {
          return { ...tab, ...payload.tab };
        }
        return tab;
      });
      let updatedAnalysisForTab = [];
      //check if it is a container
      if (payload.container?.oldId) {
        updatedAnalysisForTab = analysis[activeHTab.id].map((x) => {
          if (x.id === payload.container.oldId) {
            return {
              ...x,
              ...payload.container,
              childAnalysis: x.childAnalysis.map((y) => {
                if (y.id === payload.analysis.oldId) {
                  return { ...y, ...payload.analysis };
                }
                return y;
              }),
            };
          }
          return x;
        });
      } else {
        updatedAnalysisForTab = analysis[activeHTab.id].map((x) => {
          if (x.id === payload.analysis.oldId) {
            return { ...x, ...payload.analysis };
          }
          return x;
        });
      }
      // add the updated tab along with updated analysis
      const updatedAnalysis = {
        ...analysis,
        [payload.tab.id]: updatedAnalysisForTab,
      };
      //delete the old tab and analysis
      delete updatedAnalysis[activeHTab.id];
      return {
        ...state,
        activeHTab: {
          ...state.activeHTab,
          ...payload.tab,
        },
        HTabs: newHTab,
        analysis: { ...updatedAnalysis },
      };
    case UPDATE_MODIFIED_QUEUE:
      if (payload.removeTab) {
        const newModifiedQueue = { ...state.modifiedQueue };
        delete newModifiedQueue[payload.tabId];
        return {
          ...state,
          modifiedQueue: newModifiedQueue,
        };
      } else if (payload.type === "remove_container") {
        return {
          ...state,
          modifiedQueue: {
            ...state.modifiedQueue,
            [payload.tabId]: [
              ...(state.modifiedQueue[payload.tabId]?.filter(
                (x) =>
                  (payload.oldContainerId &&
                    x.containerId !== payload.oldContainerId) ||
                  (!payload.oldContainerId &&
                    x.containerId !== payload.containerId)
              ) || []),
            ],
          },
        };
      } else if (payload.type === "updateTab") {
        const nMQ = { ...(state?.modifiedQueue || {}) };
        nMQ[payload.newTabId] = [...(nMQ?.[payload.tabId] || [])];
        nMQ[payload.tabId] && delete nMQ[payload.tabId];
        return {
          ...state,
          modifiedQueue: nMQ,
        };
      } else if (payload.type === "remove") {
        if (
          !state.modifiedQueue[state.activeHTab.id]?.find(
            (x) => x.id === payload.id
          )
        ) {
          return state;
        }
        return {
          ...state,
          modifiedQueue: {
            ...state.modifiedQueue,
            [state.activeHTab.id]: state.modifiedQueue[
              state.activeHTab.id
            ]?.filter((x) => x.id !== payload.id),
          },
        };
      } else if (payload.type === "add") {
        if (
          state.modifiedQueue[state.activeHTab.id]?.find(
            (x) => x.id === payload.id
          )
        ) {
          return state;
        } else
          return {
            ...state,
            modifiedQueue: {
              ...state.modifiedQueue,
              [state.activeHTab.id]: [
                ...(state.modifiedQueue[state.activeHTab.id] || []),
                payload,
              ],
            },
          };
      } else if (
        state.modifiedQueue[state.activeHTab.id]?.find(
          (x) => x.id === payload.id
        )
      ) {
        return {
          ...state,
          modifiedQueue: {
            ...state.modifiedQueue,
            [state.activeHTab.id]: state.modifiedQueue[
              state.activeHTab.id
            ].filter((x) => x.id !== payload.id),
          },
        };
      } else
        return {
          ...state,
          modifiedQueue: {
            ...state.modifiedQueue,
            [state.activeHTab.id]: [
              ...(state.modifiedQueue[state.activeHTab.id] || []),
              payload,
            ],
          },
        };

    case START_SAVING_ANALYSIS:
      return {
        ...state,
        isSaving: true,
      };
    case STOP_SAVING_ANALYSIS:
      return {
        ...state,
        isSaving: false,
      };

    default:
      return state;
  }
};

export default AnalyticsReducer;
