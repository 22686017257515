import {
  GET_DATASET_VERSION_DATA_STARTED,
  GET_DATASET_VERSION_DATA_SUCCESS,
  GET_DATASET_VERSION_DATA_ERROR,
  CLEAR_DATASET_VERSION_DATA,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetVersionsReducer = (
  state = initialStates.datasetVersionData,
  { type, payload }
) => {
  switch (type) {
    case GET_DATASET_VERSION_DATA_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_VERSION_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
          cleanDataset: {
            ...state.data.cleanDataset,
            ...payload.columns,
          },
          columnInfo: [...payload?.columnInfo],
        },
        error: null,
        loading: false,
      };
    case GET_DATASET_VERSION_DATA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_DATASET_VERSION_DATA:
      return {
        ...initialStates.datasetVersionData,
      };
    default:
      return state;
  }
};

export default DatasetVersionsReducer;
