import {
  GET_DATASET_VERSION_DATA_CLEANING_STARTED,
  GET_DATASET_VERSION_DATA_CLEANING_SUCCESS,
  GET_DATASET_VERSION_DATA_CLEANING_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetVersionsDataCleaningReducer = (
  state = initialStates.datasetVersionDataCleaning,
  { type, payload }
) => {
  switch (type) {
    case GET_DATASET_VERSION_DATA_CLEANING_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_VERSION_DATA_CLEANING_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_DATASET_VERSION_DATA_CLEANING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default DatasetVersionsDataCleaningReducer;
