let initialStates = {
  dataMapping: null,
  dataManipulation: {
    data: [],
    manipulation: {},
  },
  broadcasts: {
    dataset: {
      id: null,
      reload: false,
    },
  },
  globalModals: {
    open: "",
    types: ["support", "feedback"],
  },
  fileUpload: {
    files: [],
    pending: 0,
    completed: 0,
    failed: 0,
  },
  auth: {
    record: null,
    error: {},
    loading: false,
  },
  settings: {
    activeDir: "ltr",
    activeThemeLayout: "vertical",
    activeTheme: "light",
    activeSidebarType: "full",
    activeLogoBg: "skin5",
    activeNavbarBg: "skin5",
    activeSidebarBg: "skin5",
    activeSidebarPos: "fixed",
    activeHeaderPos: "fixed",
    activeLayout: "full",
  },
  globalTimer: {
    counter: 0,
  },
  errors: {
    openDialog: false,
    title: "",
    details: null,
  },
  datasets: {
    data: [],
    error: null,
    loading: false,
    files: {},
  },
  analytics: {
    isSaving: false,
    data: [],
    savedHTabs: [],
    analysisTypes: [],
    modifiedQueue: {},
    HTabs: [{ name: "Main Board", id: 0 }],
    activeHTab: { name: "Main Board", id: 0 },
    dataAnalytics: {
      datasetOptions: [],
      datasetVersionOptions: {},
      columns: {},
    },
    analysis: {},
    pinnedCharts: [],
    error: null,
    loading: false,
    analyticDetails:{
      name:"",
      description:""
    }
  },
  datasetsInfo: {
    data: {},
    error: null,
    loading: false,
  },
  datasetsFiles: {
    dataset: {
      data: {
        tableData: [],
      },
      metrics: {},
      error: null,
      loading: false,
      pagination: {
        totalPages: 1,
        pageSize: 5,
      },
    },
    prediction: {
      data: {
        tableData: [],
      },
      metrics: {},
      error: null,
      loading: false,
      pagination: {
        totalPages: 1,
        pageSize: 5,
      },
    },
  },
  datasetVersions: {
    data: [],
    error: null,
    loading: false,
  },
  datasetVersionData: {
    data: [],
    error: null,
    loading: false,
  },
  datasetVersionDetailData: {
    data: null,
    error: null,
    loading: false,
  },
  datasetVersionDataCleaning: {
    data: [],
    error: null,
    loading: false,
  },
  salma: {
    data: {},
    error: null,
  },
  person: {
    data: {},
    error: null,
    loading: false,
  },
  mlEngine: {
    data: {},
    error: null,
    loading: false,
  },
  workspaces: {
    data: [],
    error: null,
    loading: false,
    pagination: {
      totalPages: 1,
      pageSize: 5,
    },
  },
  workspaceStats: {
    data: [],
    error: null,
    loading: false,
  },
  workspaceModels: {
    data: [],
    error: null,
    loading: false,
  },
  sharedWorkspaces: {
    data: [],
    error: null,
    loading: false,
  },
  analyticsCards: {
    data: [],
    error: null,
    loading: false,
  },
  userNotifications: {
    data: [],
    error: null,
    loading: false,
  },
  dataTable: {},
  deleteModal: {
    open: false,
    successMessage: "",
    errorMessage: "",
    loading: false,
    errorException: null,
  },
  successModal: {
    open: false,
  },
  signalR: {
    data: {},
    counter: 0,
    error: null,
  },
  userHelper: {
    active: false,
    run: false,
    currentStep: 0,
    isCompleted: false,
  },
};

export default initialStates;
