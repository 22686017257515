
import {  
    POST_TRAIN_STARTED,     
    POST_TRAIN_SUCCESS,     
    POST_TRAIN_ERROR,          
    } from '../../../../constants';
  import initialStates from "../../../../initail-states";
  
  const MLEngineReducer = (state = initialStates.mlEngine, { type, payload }) => {
    switch (type) {
      case POST_TRAIN_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case POST_TRAIN_SUCCESS:
        return {
          ...state,
          data: [...state.data, payload],
          error: null,
          loading: false,
        };
      case POST_TRAIN_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      default:
        return state;
    }
  }
  
  export default MLEngineReducer;