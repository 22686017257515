import {
  PUT_ANALYTICS_CARD_STARTED,
  PUT_ANALYTICS_CARD_SUCCESS,
  PUT_ANALYTICS_CARD_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const AnalyticsCardsReducer = (
  state = initialStates.analyticsCards,
  { type, payload }
) => {
  switch (type) {
    case PUT_ANALYTICS_CARD_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PUT_ANALYTICS_CARD_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case PUT_ANALYTICS_CARD_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AnalyticsCardsReducer;
