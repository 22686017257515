import {
  GET_USER_NOTIFICATIONS_STARTED,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_ERROR,
  CLEAR_NOTIFICATIONS,
} from "../../../constants";
import initialStates from "../../../initail-states";

const userNotificationsReducer = (state = initialStates.userNotifications, { type, payload }) => {
  switch (type) {
    case GET_USER_NOTIFICATIONS_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_USER_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
      case CLEAR_NOTIFICATIONS:
        return {
          ...state,
          data: initialStates.userNotifications.data,
          error: null,
          loading: false,

        }
    default:
      return state;
  }
}

export default userNotificationsReducer;