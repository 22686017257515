import {
  CLEAR_GLOBAL_MODAL, OPEN_GLOBAL_MODAL
} from "../../../constants";
import initialStates from "../../../initail-states";

  const AnalyticsReducer = (
    state = initialStates.globalModals,
    { type, payload }
  ) => {
    switch (type) {
      case OPEN_GLOBAL_MODAL:
        return {
          ...state,
          open: payload,
        };
        case CLEAR_GLOBAL_MODAL:
            return{
                ...state,
                open:""
            }

        default:
        return state;
    }
  };

  export default AnalyticsReducer;
