import {
  GET_WORKSPACE_STATS_STARTED,
  GET_WORKSPACE_STATS_SUCCESS,
  GET_WORKSPACE_STATS_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const WorkspaceStatsReducer = (
  state = initialStates.workspaceStats,
  { type, payload }
) => {
  switch (type) {
    case GET_WORKSPACE_STATS_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_WORKSPACE_STATS_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_WORKSPACE_STATS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default WorkspaceStatsReducer;
