import { RESET_TIMER, START_TIMER } from "../../../constants";
import initialStates from "../../../initail-states";

const GlobalTimerReducer = (state = initialStates.globalTimer, action) => {
  switch (action.type) {
    case RESET_TIMER:
      return {
        ...state,
        counter: 0,
      };
    case START_TIMER:
      return {
        ...state,
        counter: action.counter,
      };
    default:
      return state;
  }
};

export default GlobalTimerReducer;
