import {
  GET_WORKSPACE_MODEL_STARTED,
  GET_WORKSPACE_MODEL_SUCCESS,
  GET_WORKSPACE_MODEL_ERROR,
  CLEAR_WORKSPACE_MODALS,
} from "../../../constants";
import initialStates from "../../../initail-states";

const WorkspaceModelReducer = (
  state = initialStates.workspaceModels,
  { type, payload }
) => {
  switch (type) {
    case GET_WORKSPACE_MODEL_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_WORKSPACE_MODEL_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_WORKSPACE_MODEL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
      case CLEAR_WORKSPACE_MODALS:
        return {
          ...state,
          data:[]
        }
    default:
      return state;
  }
};

export default WorkspaceModelReducer;
