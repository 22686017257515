import { CLEAR_DATA_MAPPING, SET_DATA_MAPPING } from "../../constants";
import initialStates from "../../initail-states";

const dataMappingReducer = (state = initialStates.dataMapping, actions) => {
  switch (actions.type) {
    case SET_DATA_MAPPING:
      return {
        ...actions.payload,
      };
    case CLEAR_DATA_MAPPING:
      return null;
    default:
      return state;
  }
};

export default dataMappingReducer;
