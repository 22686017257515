import {
  PUBLIC_ERROR_EMPTY,
  PUBLIC_ERROR_RECEIVED
} from "../../../constants";
import initialStates from "../../../initail-states";

const PublicErrorReducer = (state = initialStates.errors, { type, payload }) => {
  switch (type) {
    case PUBLIC_ERROR_EMPTY:
      return {
        ...state,
        openDialog: false,
        title: '',
        details: null
      };
    case PUBLIC_ERROR_RECEIVED:
      return {
        ...state,
        openDialog: true,
        title: payload.title,
        details: payload.details
      };
    default:
      return state;
  }
}

export default PublicErrorReducer;