import {
  GET_DATASET_STARTED,
  GET_DATASET_SUCCESS,
  GET_DATASET_ERROR,
  POST_DATASET_STARTED,
  POST_DATASET_SUCCESS,
  POST_DATASET_ERROR,
  PUT_DATASET_STARTED,
  PUT_DATASET_SUCCESS,
  PUT_DATASET_ERROR,
  DELETE_DATASET_STARTED,
  DELETE_DATASET_SUCCESS,
  DELETE_DATASET_ERROR,
  ADD_DATASET_FILE,
  SET_DATASET_FILE,
  CLEAR_DATASET,
  DELETE_DATASET_FILE,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetReducer = (state = initialStates.datasets, { type, payload }) => {
  switch (type) {
    case GET_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case POST_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case POST_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case PUT_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PUT_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case PUT_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_DATASET_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_DATASET_SUCCESS:
      return {
        ...state,
        data: payload.record,
        error: null,
        loading: false,
      };
    case DELETE_DATASET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_DATASET_FILE:
      const DeletedExist = state.files?.[payload.datasetId]?.find(
        (file) => file.ID === payload.data.ID
      );
    
      if(DeletedExist){
        return {
          ...state,
          files: {
            ...state.files,
            [payload.datasetId]: state.files[payload.datasetId].filter((file) =>
               file.ID !== payload.data.ID
            ),
          },
        };
      }
  break;
    case ADD_DATASET_FILE:
      const exist = state.files?.[payload.datasetId]?.find(
        (file) => file.ID === payload.data.ID
      );

      if (exist) {
        // update the old object with ID with new object
    
          return {
            ...state,
            files: {
              ...state.files,
              [payload.datasetId]: state.files[payload.datasetId].map((file) =>
                file.ID === payload.data.ID ? payload.data : file
              ),
            },
          };
        
      
      }
      return {
        ...state,
        files: {
          ...state.files,
          [payload.datasetId]: [
            payload.data,
            ...state.files[payload.datasetId],
          ],
        },
      };
    case SET_DATASET_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.datasetId]: payload.data,
        },
      };
    case CLEAR_DATASET:
      return {
        ...initialStates.datasets,
      };
    default:
      return state;
  }
};

export default DatasetReducer;
