import { ENV } from "./env";
const contactEmail = "nubylasupport@nybl.ai";
const favicon = require("../assets/images/favicon.svg").default;
const logo = require("../assets/images/nubyla-svg-white.svg").default;
let baseURL = null;
let baseURLApi = null;
let photosURL = null;
let facebookRedirectURL = null;
let facebookID = "3129879777259213";
let googleClientID = null;
let baseURLSignalR = "";
let dataStreamEnabled = true;
const primaryColor="#7258b5"

if (ENV === "live") {
  //For Live Host
  baseURL = "https://nubyla.nybl.ai";
  baseURLApi = `${baseURL}/api`;
  photosURL = `${baseURL}/photos/`;
  facebookRedirectURL = "https://nubyla.nybl.ai/authentication/login";
  googleClientID =
    "379019888380-jq7v8hm2enumq6p6qbc17qbi0oame6lt.apps.googleusercontent.com";
  baseURLSignalR = `${baseURLApi}/notificationHub`;
} else if (ENV === "staging") {
  //For Remote Host
  baseURL = "https://nubylatst.nybl.ai";
  baseURLApi = `${baseURL}/api`;
  photosURL = `${baseURL}/photos/`;
  facebookRedirectURL = "https://nubylatst.nybl.ai/authentication/login";
  googleClientID =
    "379019888380-7f191k5vnj12mhpo7vbontvcs2fk1id2.apps.googleusercontent.com";
  baseURLSignalR = `${baseURLApi}/notificationHub`;
} else if (ENV === "dev") {
  //For Remote Host
  baseURL = "https://nubyladev.nybl.ai";
  baseURLApi = `${baseURL}/api`;
  photosURL = `${baseURL}/photos/`;
  facebookRedirectURL = "https://nubyladev.nybl.ai/authentication/login";
  googleClientID = "379019888380-ni8b9cm8i5668gaaeb9dsbbolrbuuk11.apps.googleusercontent.com";
  baseURLSignalR = `${baseURLApi}/notificationHub`;
} else if (ENV === "local") {
  //For Local Host IIS Express Debugging.
  baseURL = "https://localhost";
  baseURLApi = `${baseURL}:8999`; //default5001 - :44352
  photosURL = `${baseURL}/photos/`;
  facebookRedirectURL = "https://localhost:3000/authentication/login";
  googleClientID =
    "379019888380-0a9vd6meqvsk1ac12tn3togok80pu7eg.apps.googleusercontent.com";
  baseURLSignalR = `${baseURLApi}/notificationHub`;
}

export {
  contactEmail,
  favicon,
  logo,
  baseURL,
  baseURLApi,
  photosURL,
  googleClientID,
  facebookRedirectURL,
  facebookID,
  dataStreamEnabled,
  baseURLSignalR,
  primaryColor
};
