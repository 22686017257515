import {
    GET_ALGORITHM_TYPES_STARTED,
    GET_ALGORITHM_TYPES_SUCCESS,
    GET_ALGORITHM_TYPES_ERROR,
    GET_ALGORITHMS_STARTED,
    GET_ALGORITHMS_SUCCESS,
    GET_ALGORITHMS_ERROR,
  } from "../../../constants";
  import initialStates from "../../../initail-states";
  
  const MLEngineReducer = (state = initialStates.mlEngine, { type, payload }) => {
    switch (type) {
      case GET_ALGORITHM_TYPES_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case GET_ALGORITHM_TYPES_SUCCESS:
        return {
          ...state,
          data: payload,
          error: null,
          loading: false,
        };
      case GET_ALGORITHM_TYPES_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      case GET_ALGORITHMS_STARTED:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case GET_ALGORITHMS_SUCCESS:
        return {
          ...state,
          data: payload,
          error: null,
          loading: false,
        };
      case GET_ALGORITHMS_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };  
      default:
        return state;
    }
  }
  
  export default MLEngineReducer;