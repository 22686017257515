
import { CLEAR_DELETE_MODAL_DATA, SET_DELETE_MODAL_DATA } from "../../../constants";
import initialStates from "../../../initail-states";


const DeleteModalReducer = (state = initialStates.deleteModal, { type, payload }) => {
    switch (type) {
      case SET_DELETE_MODAL_DATA:
        return {
          ...state,
          ...payload
        };
        case CLEAR_DELETE_MODAL_DATA:
            return {
                ...state,
                ...initialStates.deleteModal
            }
      default:
        return state;
    }
  }
  export default DeleteModalReducer