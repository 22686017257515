import {
  GET_DATASET_VERSION_DETAIL_DATA_STARTED,
  GET_DATASET_VERSION_DETAIL_DATA_SUCCESS,
  GET_DATASET_VERSION_DETAIL_DATA_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetVersionsDetailReducer = (
  state = initialStates.datasetVersionDetailData,
  { type, payload }
) => {
  switch (type) {
    case GET_DATASET_VERSION_DETAIL_DATA_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_VERSION_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_DATASET_VERSION_DETAIL_DATA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default DatasetVersionsDetailReducer;
