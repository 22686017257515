import {
  GET_DATASET_INFO_STARTED,
  GET_DATASET_INFO_SUCCESS,
  GET_DATASET_INFO_ERROR,
} from "../../../constants";
import initialStates from "../../../initail-states";

const DatasetInfoReducer = (
  state = initialStates.datasetsInfo,
  { type, payload }
) => {
  switch (type) {
    case GET_DATASET_INFO_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DATASET_INFO_SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };
    case GET_DATASET_INFO_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default DatasetInfoReducer;
